import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";

import { TextField, Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import * as AzureSDK from "microsoft-cognitiveservices-speech-sdk";

import axios from "axios";

import * as wavefile from "wavefile"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tts: null,
      showAlert: false,
    };
  }

  handleClick = () => {
    this.setState({ showAlert: true });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showAlert: false });
  };

  handleChange = (text) => {
    this.setState({ tts: text });
  };

  handleSubmit = async () => {
    let { tts } = this.state;
    console.log(tts);
    if (!tts) {
      this.setState({ showAlert: true });
    } else {
      try {
        let url =
          "https://eastus.voice.speech.microsoft.com/cognitiveservices/v1?deploymentId=2d16bfae-ba35-4ebb-a4c0-0e2c6cddef57";
        let authUrl =
          "https://eastus.api.cognitive.microsoft.com/sts/v1.0/issuetoken";

        let authKey = "cdd53bcab7b446fbadd1380f897636d5";

        let response = await axios.post(
          authUrl,
          {},
          { headers: { "Ocp-Apim-Subscription-Key": authKey } }
        );
        console.log(response);
        let accessToken = response.data;
        let config = {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/ssml+xml",
            "X-Microsoft-OutputFormat": "riff-24khz-16bit-mono-pcm",
            // "User-Agent": "YOUR_RESOURCE_NAME",
          },
        };
        let body = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xml:lang="en-US"><voice name="v2-model">${tts}</voice></speak>`;
        // let audioResponse = await axios.post(url, body, config);
        let audioResponse = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/ssml+xml",
            "X-Microsoft-OutputFormat": "riff-24khz-16bit-mono-pcm",
            // "User-Agent": "YOUR_RESOURCE_NAME",
          },
          body: body
        })


        const reader = audioResponse.body.getReader()

        let values = null;

        const readStream = (values) => {

          let readChunck = () => {
            reader.read().then(({ done, value }) => {

              if (done) {
                return this.handlePlay(values)



              }
              if (!values) values = value
              else {
                let temp = new Uint8Array(values.length + value.length)
                temp.set(values)
                temp.set(value, values.length)
                values = temp
              }
              readChunck()
            })
          }
          readChunck()
          console.log(this)

          return values
        }

        readStream(values)
      } catch (error) {
        console.log(error);
      }
    }
  };


  handlePlay = (values) => {
    const blob = new Blob([values], {
      type: 'audio/wav'
    });
    const urlDownload = URL.createObjectURL(blob);

    let sound = new Audio(urlDownload)

    sound.volume = 1.0
    console.log(sound)

    sound.play()
    // console.log(blob)


    // var a = document.createElement("a");
    // document.body.appendChild(a);
    // a.style = "display: none";

    // a.href = urlDownload;
    // a.download = "test.wav";
    // a.click();
    // window.URL.revokeObjectURL(urlDownload);
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <form noValidate autoComplete="off">
            <TextField
              label="What do you want to hear?"
              multiline
              variant="filled"
              className="Text-Input"
              onChange={(e) => {
                this.handleChange(e.target.value);
              }}
            />
          </form>
          <Button
            color="primary"
            id="submit-button"
            onClick={() => {
              this.handleSubmit();
            }}
          >
            Generate Text to Speech
          </Button>

          <Snackbar
            open={this.state.showAlert}
            autoHideDuration={2000}
            onClose={this.handleClose}
          >
            <Alert onClose={this.handleClose} severity="error">
              Enter Text to Generate
            </Alert>
          </Snackbar>
        </header>
      </div>
    );
  }
}

export default App;
